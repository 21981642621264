import "./src/base/styles/main.scss";

function initGTM() {
  if (window.isGTMLoaded) {
    return;
  }

  window.isGTMLoaded = true;

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src =
    "https://www.googletagmanager.com/gtm.js?id=" + process.env.GATSBY_GTM_ID;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
  };

  document.head.appendChild(script);

  // Remove all event listeners since GTM is now loaded
  document.removeEventListener("scroll", loadGTM);
  document.removeEventListener("mousemove", loadGTM);
  document.removeEventListener("touchstart", loadGTM);
}

function loadGTM(event) {
  initGTM();
}

export const onClientEntry = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== "loading") {
      setTimeout(initGTM, 3500);
    }
  };

  // Add event listeners to load GTM on user interaction
  document.addEventListener("scroll", loadGTM, { once: true });
  document.addEventListener("mousemove", loadGTM, { once: true });
  document.addEventListener("touchstart", loadGTM, { once: true });
};
